import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

export type WorldTheme = 'light' | 'dark';

const LOCAL_STORAGE_KEY = '@atrius/theme';
const DEFAULT_THEME = (localStorage.getItem(LOCAL_STORAGE_KEY) as WorldTheme) || 'light';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private themeSubject = new BehaviorSubject<WorldTheme>(DEFAULT_THEME);
  theme$ = this.themeSubject.asObservable();

  get theme(): WorldTheme {
    return this.themeSubject.value;
  }

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.loadTheme();
  }

  toggleTheme() {
    this.setTheme(this.theme === 'light' ? 'dark' : 'light');
  }

  setTheme(theme: WorldTheme) {
    this.applyTheme(theme);
    this.saveTheme(theme);

    this.themeSubject.next(theme);
  }

  private applyTheme(theme: WorldTheme) {
    const themeClass = this.getThemeClass(theme);
    this.document.body.className = themeClass;
  }

  private saveTheme(theme: WorldTheme) {
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY, theme);
    } catch (error) {}
  }

  private loadTheme() {
    try {
      const theme = localStorage.getItem(LOCAL_STORAGE_KEY) as WorldTheme | null;
      if (theme) {
        this.setTheme(theme);
      }
    } catch (error) {}
  }

  private getThemeClass(theme: WorldTheme): string {
    return `world-${theme}-theme`;
  }
}
