import * as firebaseui from 'firebaseui';
import firebase from 'firebase/app';

const config: firebaseui.auth.Config = {
  signInFlow: 'redirect',
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  queryParameterForSignInSuccessUrl: 'next',
  immediateFederatedRedirect: false,
};

export default config;
