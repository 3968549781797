import { Component, Input } from '@angular/core';
import {
  ActionButtonMenuItemConfig,
  ActionToggleMenuItemConfig,
  ActionSubMenuConfig,
  ActionMenuItem
} from '../action-config';
import { trackByAction } from '../action-utils';

// DEFAULT - button menu item
@Component({
  selector: 'atrius-action-menu-item',
  template: `
    <button
      mat-menu-item
      class="action-menu-item"
      [disabled]="item.disabled"
      (click)="onMenuClick()"
    >
      <atrius-icon *ngIf="showIcon && item.icon" [iconName]="item.icon"></atrius-icon>
      <span>{{ item.name }}</span>
    </button>
  `,
  styleUrls: ['./action-menu-item.component.scss']
})
export class ActionMenuItemComponent {
  @Input() item: ActionButtonMenuItemConfig;
  @Input() showIcon: boolean;

  onMenuClick() {
    if (this.item.disabled || !this.item.callback) {
      return;
    }

    this.item.callback();
  }
}

// toggle menu item
@Component({
  selector: 'atrius-action-toggle-menu-item',
  template: `
    <button
      class="action-menu-item"
      data-test="actionToggleMenuItemButton"
      mat-menu-item
      [disabled]="item.disabled"
      (click)="onMenuClick($event)"
    >
      <atrius-icon *ngIf="showIcon && item.icon" [iconName]="item.icon"></atrius-icon>
      <span>{{ item.name }}</span>
      <span class="action-menu-item--right-area">
        <mat-slide-toggle [checked]="checked" (change)="onToggleChange()"></mat-slide-toggle>
      </span>
    </button>
  `,
  styleUrls: ['./action-menu-item.component.scss']
})
export class ActionToggleMenuItemComponent {
  @Input() item: ActionToggleMenuItemConfig;
  @Input() showIcon: boolean;

  get checked(): boolean {
    return this.item.toggle ? this.item.toggle.value : false;
  }

  onMenuClick(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.toggleValue();
  }

  onToggleChange() {
    this.toggleValue();
  }

  private toggleValue() {
    if (this.item.disabled || !this.item.callback) {
      return;
    }

    this.item.callback(!this.checked);
  }
}

@Component({
  selector: 'atrius-action-sub-menu',
  template: `
    <button
      mat-menu-item
      class="action-menu-item"
      [disabled]="item.disabled"
      (click)="toggleMenu($event)"
    >
      <atrius-icon *ngIf="showIcon && item.icon" [iconName]="item.icon"></atrius-icon>
      <span>{{ item.name }}</span>
      <span class="action-menu-item--right-area">
        <atrius-icon [iconName]="icon"></atrius-icon>
      </span>
    </button>
    <ng-container *ngIf="opened">
      <button
        *ngFor="let subItem of item.items; trackBy: trackByAction"
        mat-menu-item
        class="action-menu-item--sub-item"
        [disabled]="subItem.disabled"
        (click)="onMenuClick(subItem)"
      >
        {{ subItem.name }}
      </button>
    </ng-container>
  `,
  styleUrls: ['./action-menu-item.component.scss']
})
export class ActionSubMenuComponent {
  @Input() item: ActionSubMenuConfig;
  @Input() showIcon: boolean;

  opened: boolean;
  get icon() {
    return this.opened ? 'keyboard_arrow_up' : 'keyboard_arrow_down';
  }

  onMenuClick(action: ActionButtonMenuItemConfig) {
    if (action.disabled || !action.callback) {
      return;
    }

    action.callback();
  }

  toggleMenu(event: Event) {
    event.stopPropagation();
    this.opened = !this.opened;
  }

  trackByAction(_: number, action: ActionMenuItem | null) {
    return trackByAction(action);
  }
}
