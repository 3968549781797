import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { AvatarSize } from './types';

@Component({
  selector: 'atrius-avatar',
  template: `
    <div
      class="avatar"
      [ngClass]="sizeClass"
      [style.background-color]="backgroundColor"
      [style.color]="color"
      [class.default-background]="!backgroundColor"
    >
      {{ avatarText }}
    </div>
  `,
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {
  @Input() name: string;
  @Input() email: string;
  @Input() backgroundColor: string;
  @Input() color: string;
  @Input() size: AvatarSize = 'normal';

  get sizeClass(): string {
    return this.size;
  }

  get avatarText(): string {
    return this.getInitials();
  }

  private getInitials(): string {
    if (this.name) {
      const name = this.name.trim();
      const initials = name.split(' ');
      return this.constructInitials(initials)[0];
    }

    if (this.email) {
    }
  }

  private constructInitials(elements: string[]): string {
    if (!elements || !elements.length) {
      return '';
    }

    return elements
      .filter(element => element && element.length > 0)
      .map(element => element[0].toUpperCase())
      .join('');
  }
}
