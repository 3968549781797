import { pickBy, isNumber, isString } from 'lodash-es';

export function compactObj<T>(obj: Record<string, T | undefined>): Record<string, T>;
export function compactObj<T extends object>(obj: T): Partial<T>;
export function compactObj<T extends object>(obj: T): Partial<T> {
  return pickBy(obj, v => v !== undefined);
}

type ObjOrId = number | string | { id?: number } | { id?: string } | null | undefined;

export function getId(value: number | string | { id: number }): string;
export function getId(value: ObjOrId): string | undefined;
export function getId(value: ObjOrId): string | undefined {
  if (isNumber(value)) {
    return String(value);
  } else if (isString(value)) {
    return String(value);
  } else {
    return value && value.id ? String(value.id) : undefined;
  }
}

export function sameId<T extends ObjOrId>(a: T, b: T): boolean {
  return getId(a) === getId(b);
}
