import { Injectable } from '@angular/core';

@Injectable()
export class NavigationService {
  go(url: string, blank?: boolean) {
    blank ? window.open(url, '_blank') : (window.location.href = url);
  }

  origin(): string {
    return window.location.origin;
  }
}
