import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AppBarModule } from '@atrius/ui/app-bar';
import { LogoModule } from '@atrius/ui/logo';
import { ActionModule } from '@atrius/ui/action';
import { NavToolbarModule } from '@atrius/ui/nav-toolbar';

import { WorldShellComponent } from './world-shell.component';
import { AppBarContainerComponent } from './app-bar-container.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    AppBarModule,
    LogoModule,
    ActionModule,
    NavToolbarModule,
  ],
  declarations: [WorldShellComponent, AppBarContainerComponent],
  exports: [WorldShellComponent],
})
export class WorldShellModule {}
