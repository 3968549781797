import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ActionConfig, TextSize, ButtonStyle } from '@atrius/ui/action';
import { IconSize } from '@atrius/ui/icon';
import { BehaviorSubject } from 'rxjs';
import { switchMap, map, startWith, shareReplay } from 'rxjs/operators';
import { defaultMemoize } from 'reselect';
import { DefaultActionConfig } from './nav-toolbar-config';

@Component({
  selector: 'atrius-nav-toolbar',
  template: `
    <div class="container" *ngFor="let action of toolbarActions; trackBy: trackByName">
      <atrius-action
        *ngLet="isMobile$ | async as isMobile"
        [config]="action"
        [showIcon]="showButtonsIcon"
        [showLabel]="shouldShowButtonsLabel(isMobile)"
        [menuPositionX]="menuPositionX"
        [menuPositionY]="menuPositionY"
      ></atrius-action>
    </div>
  `,
  styleUrls: ['./nav-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavToolbarComponent {
  @Input() actions: ActionConfig[];
  @Input() defaultActionConfig: DefaultActionConfig;
  @Input() menuPositionX: MenuPositionX = 'before';
  @Input() menuPositionY: MenuPositionY = 'below';
  @Input() showButtonsIcon = true;
  @Input() showButtonsLabel = false;
  @Input() showButtonsLabelOnMobile = false;

  private mobileBreakpoints$ = new BehaviorSubject([
    Breakpoints.Handset,
    Breakpoints.TabletPortrait,
  ]);

  isMobile$ = this.mobileBreakpoints$.pipe(
    switchMap((breakpoints) => this.breakpointObserver.observe(breakpoints)),
    map((result) => result.matches),
    startWith(false),
    shareReplay(1)
  );

  constructor(private breakpointObserver: BreakpointObserver) {}

  trackByName(_: number, action: ActionConfig | null) {
    return action && action.name;
  }

  shouldShowButtonsLabel(isMobile: boolean): boolean {
    return this.showButtonsLabel && (isMobile !== true || this.showButtonsLabelOnMobile);
  }

  get toolbarActions(): ActionConfig[] {
    if (!this.actions) {
      return [];
    }

    return this.getToolbarActions(this.actions, this.defaultActionConfig);
  }

  private getToolbarActions = defaultMemoize(
    (actions: ActionConfig[], defaultConfig: DefaultActionConfig): ActionConfig[] => {
      if (!defaultConfig) return actions;
      const { iconSize, maxItems, textSize, style } = defaultConfig;
      const mappedActions: ActionConfig[] = this.updateActions(iconSize, textSize, style, actions);
      if (!maxItems || actions.length <= maxItems) {
        return mappedActions;
      }
      const { moreLabel } = defaultConfig;
      const topBarActions: ActionConfig[] = mappedActions.slice(0, maxItems - 1);
      const moreActions = mappedActions.slice(maxItems - 1, actions.length);
      topBarActions.push(this.moreAction(iconSize, textSize, style, moreLabel, moreActions));
      return topBarActions;
    }
  );

  private updateActions = defaultMemoize(
    (
      iconSize: IconSize | undefined,
      textSize: TextSize | undefined,
      style: ButtonStyle | undefined,
      actions: ActionConfig[]
    ): ActionConfig[] => {
      return actions.map((action: ActionConfig) => ({
        ...action,
        iconSize: iconSize || action.iconSize,
        textSize: textSize || action.textSize,
        style: style || action.style,
      }));
    }
  );

  private moreAction = defaultMemoize(
    (
      iconSize: IconSize | undefined,
      textSize: TextSize | undefined,
      style: ButtonStyle | undefined,
      moreLabel: string | undefined,
      menuItems: ActionConfig[]
    ): ActionConfig => {
      return {
        icon: 'more_vert',
        iconSize,
        menuItems,
        name: moreLabel || 'More',
        textSize,
        style,
      };
    }
  );
}
