import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivate, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectToLogin = () => redirectUnauthorizedTo(['login']);
const redirectToHome = () => redirectLoggedInTo(['']);

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('@world/login').then((m) => m.LoginModule),
    // ...canActivate(redirectToHome),
  },
  {
    path: 'scenes',
    loadChildren: () => import('@world/scene').then((m) => m.SceneModule),
    // ...canActivate(redirectToLogin),
  },
  {
    path: 'plans',
    loadChildren: () => import('@world/plan').then((m) => m.PlanModule),
    // ...canActivate(redirectToLogin),
  },
  {
    path: 'views',
    loadChildren: () => import('@world/view').then((m) => m.ViewModule),
    // ...canActivate(redirectToLogin),
  },
  {
    path: '',
    redirectTo: 'scenes',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () => import('@world/error').then((m) => m.ErrorPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
