/**
 * Inspired by: https://github.com/angular/angular/issues/15280
 */

import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

export interface NgLetContext {
  $implicit: any;
  ngLet: any;
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ngLet]',
})
export class NgLetDirective implements OnInit {
  private context: NgLetContext = {
    $implicit: null,
    ngLet: null,
  };

  @Input()
  set ngLet(value: any) {
    this.context.$implicit = this.context.ngLet = value;
  }

  constructor(private vcr: ViewContainerRef, private tplRef: TemplateRef<NgLetContext>) {}

  ngOnInit(): void {
    this.vcr.createEmbeddedView(this.tplRef, this.context);
  }
}
