import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';
import { isEmpty } from 'lodash-es';
import { ActionDisplay, trackByAction } from '../action-utils';
import { ActionMenuItem, ActionSubMenuConfig, ActionToggleMenuItemConfig } from '../action-config';

@Component({
  selector: 'atrius-action-menu',
  template: `
    <button
      mat-button
      [color]="display.color"
      [disabled]="display.disabled"
      [matTooltip]="display.tooltip"
      [ngClass]="display.buttonClassList"
      [matMenuTriggerFor]="menu"
      (click)="$event.stopPropagation()"
    >
      <atrius-avatar
        *ngIf="display.avatar"
        [name]="display.avatar.name"
        [backgroundColor]="display.avatar.backgroundColor"
        [color]="display.avatar.color"
        [size]="display.iconSize"
      ></atrius-avatar>
      <atrius-icon
        *ngIf="display.icon"
        [iconName]="display.icon"
        [size]="display.iconSize"
      ></atrius-icon>
      <span *ngIf="display.label" class="label" [ngClass]="display.textSize">
        {{ display.label }}
      </span>
    </button>

    <mat-menu
      #menu="matMenu"
      [hasBackdrop]="true"
      [overlapTrigger]="false"
      [xPosition]="menuPositionX"
      [yPosition]="menuPositionY"
    >
      <ng-template matMenuContent>
        <ng-content></ng-content>

        <ng-container *ngFor="let item of menuItems; trackBy: trackByAction">
          <div [ngSwitch]="getMenuItemType(item)">
            <atrius-action-toggle-menu-item
              *ngSwitchCase="'toggle'"
              [item]="item"
              [showIcon]="showIcon"
            ></atrius-action-toggle-menu-item>
            <atrius-action-sub-menu
              *ngSwitchCase="'sub-menu'"
              [item]="item"
              [showIcon]="showIcon"
            ></atrius-action-sub-menu>
            <atrius-action-menu-item
              *ngSwitchDefault
              [item]="item"
              [showIcon]="showIcon"
            ></atrius-action-menu-item>
          </div>
          <div *ngIf="item.divider" class="action-divider"></div>
        </ng-container>
      </ng-template>
    </mat-menu>
  `,
  styleUrls: ['./action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionMenuComponent {
  @ViewChild(MatButton, { static: true }) set butonRef(value: MatButton) {
    value._getHostElement().classList.remove('mat-button');
  }

  @Input() display: ActionDisplay;
  @Input() items: ActionMenuItem[];
  @Input() menuPositionX: MenuPositionX = 'before';
  @Input() menuPositionY: MenuPositionY = 'below';
  @Input() showIcon: boolean;

  @Output() actionClick = new EventEmitter<ActionMenuItem>();

  trackByAction(_: number, action: ActionMenuItem | null) {
    return trackByAction(action);
  }

  getMenuItemType(item: ActionMenuItem): string {
    if (this.isToggle(item)) {
      return 'toggle';
    } else if (this.hasItems(item)) {
      return 'sub-menu';
    }
    return 'default';
  }

  get menuItems() {
    return this.items.map(item => ({
      ...item,
      callback: item.callback || this.onActionClick(item)
    }));
  }

  private onActionClick(action: ActionMenuItem) {
    return () => this.actionClick.emit(action);
  }

  private hasItems(menuItem: ActionSubMenuConfig): boolean {
    return !isEmpty(menuItem.items);
  }

  private isToggle(menuItem: ActionToggleMenuItemConfig): boolean {
    return Boolean(menuItem.toggle);
  }
}
