import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

import { IconModule } from '@atrius/ui/icon';
import { AvatarModule } from '@atrius/ui/avatar';

import { ActionComponent } from './action.component';
import { ActionContentComponent } from './action-content.component';
import { ActionSimpleComponent } from './simple';
import {
  ActionMenuComponent,
  ActionSubMenuComponent,
  ActionMenuItemComponent,
  ActionToggleMenuItemComponent
} from './menu';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatBadgeModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatTooltipModule,
    IconModule,
    AvatarModule
  ],
  declarations: [
    ActionComponent,
    ActionContentComponent,
    ActionSimpleComponent,
    ActionMenuComponent,
    ActionSubMenuComponent,
    ActionMenuItemComponent,
    ActionToggleMenuItemComponent
  ],
  exports: [ActionComponent, ActionContentComponent]
})
export class ActionModule {}
