import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { defaultMemoize } from 'reselect';
import { ActionConfig, ActionMenuItem } from './action-config';
import { ButtonColor } from './action-button';
import {
  getActionType,
  ActionDisplay,
  getButtonClassList,
  getColor,
  ActionType
} from './action-utils';

@Component({
  selector: 'atrius-action',
  template: `
    <ng-container *ngIf="config" [ngSwitch]="type">
      <atrius-action-menu
        *ngSwitchCase="ActionType.MENU"
        [display]="display"
        [items]="config.menuItems"
        [menuPositionX]="menuPositionX"
        [menuPositionY]="menuPositionY"
        [showIcon]="showIcon"
        (actionClick)="onActionClick($event)"
      >
        <ng-content select="atrius-action-content"></ng-content>
      </atrius-action-menu>
      <atrius-action-simple
        *ngSwitchDefault
        [display]="display"
        (actionClick)="onActionClick($event)"
      ></atrius-action-simple>
    </ng-container>
  `,
  styleUrls: ['./action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionComponent {
  @Input() config: ActionConfig;
  @Input() color: ButtonColor;
  @Input() menuPositionX: MenuPositionX = 'before';
  @Input() menuPositionY: MenuPositionY = 'below';
  @Input() showIcon: boolean;
  @Input() showLabel: boolean;

  @Output() actionClick = new EventEmitter<ActionConfig | ActionMenuItem>();

  readonly ActionType = ActionType;
  get type(): ActionType {
    return getActionType(this.config);
  }

  get display(): ActionDisplay {
    return this.generateActionDisplay(this.config, this.color, this.showLabel, this.showIcon);
  }

  onActionClick(event: ActionMenuItem | undefined) {
    const action = event || this.config;

    if (!action.disabled) {
      this.callback(action);
      this.actionClick.emit(action);
    }
  }

  private callback(action: ActionConfig | ActionMenuItem, param?: any): void {
    if (action.callback) {
      action.callback(param);
    }
  }

  private generateActionDisplay = defaultMemoize(
    (
      config: ActionConfig,
      color: ButtonColor,
      showLabel: boolean,
      showIcon: boolean
    ): ActionDisplay => {
      return {
        buttonClassList: getButtonClassList(config, showLabel, showIcon),
        color: getColor(color || config),
        disabled: config.disabled,
        icon: showIcon ? config.icon : undefined,
        iconSize: config.iconSize || 'normal',
        avatar: config.avatar || undefined,
        label: showLabel ? config.name : undefined,
        textSize: config.textSize || '',
        tooltip: config.label || config.name
      };
    }
  );
}
