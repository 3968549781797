export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyC6yD3iFHJS8jwb29vcwABMmyaPg40zqAI',
    authDomain: 'auth-dev.atrius.io',
    databaseURL: 'https://atrius-dev.firebaseio.com',
    projectId: 'atrius-dev',
    storageBucket: 'atrius-dev.appspot.com',
    messagingSenderId: '853830602986',
    appId: '1:853830602986:web:eeb6765b89a82367',
    measurementId: 'G-D9LDGBB1ED',
  },
  mapbox: {
    token:
      'pk.eyJ1IjoiYXRyaXVzdGVjaCIsImEiOiJjanAwODBwb3UwdXpqM2pwNDBydGlodzkxIn0.008dDfZA13OT8MYDFS-k7w',
  },
  here: {
    token: 'lUNNMbY8-hGxgtuEeZFbl7M4kZgaMJF1ii_-JDy4G9Y',
  },
};
