import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';

import { AppBarComponent } from './app-bar.component';
import { LogoAreaComponent } from './logo-area/logo-area.component';
import { RightAreaComponent } from './right-area/right-area.component';
import { ContentComponent } from './content/content.component';

@NgModule({
  imports: [CommonModule, MatToolbarModule],
  declarations: [AppBarComponent, LogoAreaComponent, RightAreaComponent, ContentComponent],
  exports: [AppBarComponent, LogoAreaComponent, RightAreaComponent, ContentComponent],
})
export class AppBarModule {}
