import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgLetModule } from '@atrius/common/ng-let';
import { ActionModule } from '@atrius/ui/action';

import { NavToolbarComponent } from './nav-toolbar.component';

@NgModule({
  imports: [CommonModule, NgLetModule, ActionModule],
  declarations: [NavToolbarComponent],
  exports: [NavToolbarComponent],
})
export class NavToolbarModule {}
