import { Component } from '@angular/core';

@Component({
  selector: 'world-root',
  template: `
    <world-shell>
      <router-outlet></router-outlet>
    </world-shell>
  `,
})
export class AppComponent {}
