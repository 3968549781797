import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'atrius-app-bar',
  template: `
    <mat-toolbar>
      <div class="container">
        <ng-content select="atrius-app-bar-logo-area"></ng-content>
        <ng-content select="atrius-app-bar-content"></ng-content>
        <div class="spacer"></div>
        <ng-content select="atrius-app-bar-right-area"></ng-content>
      </div>
    </mat-toolbar>
  `,
  styleUrls: ['./app-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBarComponent {}
