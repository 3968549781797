import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MobileService, NavigationService, ThemeService } from '@world/services';
import { ActionConfig, ActionMenuItemConfig, ActionToggleMenuItemConfig } from '@atrius/ui/action';
import { DefaultActionConfig } from '@atrius/ui/nav-toolbar';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'world-app-bar-container',
  template: `
    <atrius-app-bar>
      <atrius-app-bar-logo-area>
        <button mat-button (click)="onHomeClick()">
          <atrius-logo></atrius-logo>
        </button>
      </atrius-app-bar-logo-area>

      <atrius-app-bar-content></atrius-app-bar-content>

      <atrius-app-bar-right-area>
        <atrius-nav-toolbar
          [defaultActionConfig]="navToolbarConfig$ | async"
          [actions]="mainActions$ | async"
          [showButtonsLabel]="true"
          [showButtonsLabelOnMobile]="false"
        ></atrius-nav-toolbar>

        <atrius-action
          *ngIf="accountAction$ | async as accountAction"
          [config]="accountAction"
          [showIcon]="true"
        >
          <atrius-action-content *ngIf="user$ | async as user">
            <div>{{ user.displayName }}</div>
            <div class="mat-small">{{ user.email }}</div>
          </atrius-action-content>
        </atrius-action>
      </atrius-app-bar-right-area>
    </atrius-app-bar>
  `,
  styleUrls: ['./app-bar-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBarContainerComponent {
  user$ = this.auth.user;

  navToolbarConfig$: Observable<DefaultActionConfig> = this.mobileService.isMobile$.pipe(
    map((isMobile) => ({
      iconSize: 'small',
      maxItems: isMobile ? 1 : undefined,
    }))
  );

  constructor(
    private auth: AngularFireAuth,
    private themeService: ThemeService,
    private navigationService: NavigationService,
    private mobileService: MobileService
  ) {}

  accountAction$: Observable<ActionConfig> = combineLatest([
    this.user$,
    this.getThemeToggleAction(),
  ]).pipe(
    map(([user, toggleThemeAction]) => {
      return {
        icon: user ? undefined : 'account_circle',
        avatar: user ? { name: user.displayName } : undefined,
        menuItems: user
          ? this.getSignedInItems(toggleThemeAction)
          : this.getSignedOutItems(toggleThemeAction),
      };
    })
  );

  mainActions$: Observable<ActionConfig[]> = this.user$.pipe(
    map((user) => (true ? this.getMainActions() : []))
  );

  onHomeClick() {
    this.navigationService.goToHome();
  }

  private getSignedInItems(toggleThemeAction: ActionToggleMenuItemConfig): ActionMenuItemConfig[] {
    const signOutAction: ActionMenuItemConfig = {
      icon: 'account_circle',
      name: 'Log Out',
      callback: () => this.auth.signOut().then(() => this.navigationService.goToLogin()),
      divider: true,
    };

    return [signOutAction, toggleThemeAction];
  }

  private getSignedOutItems(toggleThemeAction: ActionToggleMenuItemConfig): ActionMenuItemConfig[] {
    const signInAction: ActionMenuItemConfig = {
      icon: 'account_circle',
      name: 'Login',
      callback: () => this.navigationService.goToLogin(),
      divider: true,
    };

    return [signInAction, toggleThemeAction];
  }

  private getMainActions(): ActionConfig[] {
    return [
      { name: 'Scenes', callback: () => this.navigationService.goToScenes() },
      { name: 'Views', callback: () => this.navigationService.goToViews() },
      { name: 'Plans', callback: () => this.navigationService.goToPlans() },
    ];
  }

  private getThemeToggleAction(): Observable<ActionToggleMenuItemConfig> {
    return this.themeService.theme$.pipe(
      map((theme) => ({
        name: 'Dark Theme',
        toggle: { value: theme === 'dark' },
        callback: () => this.themeService.toggleTheme(),
      }))
    );
  }
}
