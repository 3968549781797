import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ActionDisplay } from '../action-utils';

@Component({
  selector: 'atrius-action-simple',
  template: `
    <button
      mat-button
      [color]="display.color"
      [disabled]="display.disabled"
      [matTooltip]="display.tooltip"
      [ngClass]="display.buttonClassList"
      (click)="onClick($event)"
    >
      <atrius-icon
        *ngIf="display.icon"
        [iconName]="display.icon"
        [size]="display.iconSize"
      ></atrius-icon>

      <span *ngIf="display.label" class="label" [ngClass]="display.textSize">
        {{ display.label }}
      </span>
    </button>
  `,
  styleUrls: ['./action-simple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionSimpleComponent {
  @ViewChild(MatButton, { static: true }) set butonRef(value: MatButton) {
    value._getHostElement().classList.remove('mat-button');
  }

  @Input() display: ActionDisplay;
  @Output() actionClick = new EventEmitter<void>();

  onClick(event: Event) {
    this.actionClick.emit();
  }
}
