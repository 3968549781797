import { isEmpty } from 'lodash-es';
import { IconSize } from '@atrius/ui/icon';
import { ActionConfig, AvatarConfig } from './action-config';
import { ButtonStyle, ButtonColor, DEFAULT_BUTTON_STYLE, TextSize } from './action-button';

const compactModeSupported: Record<ButtonStyle, boolean> = {
  'mat-button': true,
  'mat-flat-button': true,
  'mat-icon-button': true,
  'mat-raised-button': true,
  'mat-stroked-button': true,
  'mat-mini-fab': false,
  'mat-fab': false
};

// if only icon is shown
const addIconButtonClass: Record<ButtonStyle, boolean> = {
  'mat-button': false,
  'mat-flat-button': true,
  'mat-icon-button': false,
  'mat-raised-button': false,
  'mat-stroked-button': false,
  'mat-mini-fab': false,
  'mat-fab': false
};

export function getButtonStyle(
  config: ActionConfig,
  showLabel: boolean = false,
  showIcon: boolean = false
): ButtonStyle {
  if (config.style) {
    // default to mini fab insted of mixing mat-flat-button and mat-icon-button
    // note: best to specify fab instead lol

    // if (config.style === ButtonStyle.Flat && !showLabel) {
    //   return ButtonStyle.MiniFab;
    // }

    return config.style;
  }

  if (!showLabel) {
    // default to icon button if label is not shown
    return ButtonStyle.Icon;
  }

  return ButtonStyle.Normal;
}

export function getButtonClassList(
  config: ActionConfig,
  showLabel: boolean = false,
  showIcon: boolean = false
): string[] {
  const classes: string[] = [];

  const style = getButtonStyle(config, showLabel, showIcon);

  if (showIcon && addIconButtonClass[style]) {
    classes.push(ButtonStyle.Icon);
  }

  classes.push(style);

  if (config.compact && compactModeSupported[style || DEFAULT_BUTTON_STYLE]) {
    classes.push('compact');
  }

  if (config.active) {
    classes.push('active');
  }

  return classes;
}

export function getColor(colorOrConfig: ActionConfig | ButtonColor | undefined): ButtonColor {
  return typeof colorOrConfig === 'string'
    ? colorOrConfig
    : colorOrConfig.color || ButtonColor.None;
}

export function trackByAction(action: ActionConfig | null) {
  return action && (action.id || action.name);
}

export interface ActionDisplay {
  readonly buttonClassList: string[];
  readonly color: ButtonColor;
  readonly disabled?: boolean;
  readonly icon?: string;
  readonly iconSize?: IconSize;
  readonly avatar?: AvatarConfig;
  readonly label?: string;
  readonly testId?: string;
  readonly textSize?: TextSize | string;
  readonly tooltip?: string;
}

export enum ActionType {
  DEFAULT,
  MENU
}

export const getActionType = (config: ActionConfig): ActionType => {
  if (!isEmpty(config.menuItems)) {
    return ActionType.MENU;
  }

  return ActionType.DEFAULT;
};
