import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'atrius-logo',
  template: `
    <img class="light" [src]="srcLight" />
    <img class="dark" [src]="srcDark" />
  `,
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  srcLight = '/assets/logos/atrius_light_horizontal.svg';
  srcDark = '/assets/logos/atrius_dark_horizontal.svg';
}
