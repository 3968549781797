import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IconSize } from './types';

@Component({
  selector: 'atrius-icon',
  template: `
    <ng-container [ngSwitch]="iconType">
      <mat-icon *ngSwitchCase="'material'" [ngClass]="iconClass">
        <ng-content *ngIf="!iconName"></ng-content> {{ iconName }}
      </mat-icon>
    </ng-container>
  `,
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
  @Input() iconName: string;
  @Input() size: IconSize = 'normal';

  get iconClass() {
    return `atrius-icon ${this.size}`;
  }

  get iconType(): 'svg' | 'material' {
    return 'material';
  }
}
