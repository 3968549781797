import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService as BaseNavigationService } from '@atrius/common/navigation';
import { getId } from '@atrius/common/object';
import { Id } from '@atrius/model/core';
import { Plan } from '@atrius/model/mission/dronelink';
import { Scene } from '@atrius/model/scene';
import { View } from '@atrius/model/view';

@Injectable({ providedIn: 'root' })
export class NavigationService extends BaseNavigationService {
  constructor(private router: Router) {
    super();
  }

  goToHome() {
    this.router.navigateByUrl('/');
  }

  goToLogin(next?: string) {
    this.router.navigate(['login'], { queryParams: { next } });
  }

  goToNotFound() {
    this.router.navigate(['/', 404]);
  }

  // scenes
  goToScenes() {
    this.router.navigateByUrl('scenes');
  }

  goToScene(sceneOrId: Scene | Id) {
    this.router.navigate(['scenes', getId(sceneOrId)]);
  }

  // plans
  goToPlans() {
    this.router.navigateByUrl('plans');
  }

  goToPlan(planOrId: Plan | Id) {
    this.router.navigate(['plans', getId(planOrId)]);
  }

  // views
  goToViews() {
    this.router.navigateByUrl('views');
  }

  goToView(viewOrId: View | Id) {
    this.router.navigate(['views', getId(viewOrId)]);
  }
}
