import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const names = ['elevation-query:invalid-layer', 'view:no-layerview-for-layer'];

const logerror = console.error;

window.console.error = (...data: any[]) => {
  let log = true;
  for (const item of data) {
    if (item && item.name && names.includes(item.name)) {
      log = false;
    }
  }

  if (log) {
    logerror(...data);
  }
};

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
