import { Component } from '@angular/core';

@Component({
  selector: 'world-shell',
  template: `
    <world-app-bar-container></world-app-bar-container>

    <main>
      <ng-content></ng-content>
    </main>
  `,
  styleUrls: ['./world-shell.component.scss'],
})
export class WorldShellComponent {}
